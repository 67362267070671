import { Modal as ReactModal } from 'reactstrap';
import React, { useState } from 'react';
export const Modal = (props) => {
  const { id, size, onToggle, className, children } = props;
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      onToggle();
    }, 300);
  };
  return (
    <ReactModal size={size} isOpen={isOpen} toggle={handleToggle} className={className} id={id}>
      {children}
    </ReactModal>
  );
};

export default Modal;
