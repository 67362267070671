import AsyncSelect from 'react-select/async';
import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';

const ReactSelect = ({ className, loadOptions, ...rest }) => {
  const Component = loadOptions ? AsyncSelect : Select;
  let defaultProps = {};
  if (loadOptions) {
    defaultProps = {
      ...defaultProps,
      loadOptions: loadOptions,
      cacheOptions: true,
      defaultOptions: true,
    };
  }
  return (
    <Component
      {...defaultProps}
      {...rest}
      className={classNames('select-container', className)}
      classNamePrefix="select"
    />
  );
};

export default ReactSelect;
