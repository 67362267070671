import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ROUTE } from 'definitions';
import GlobalErrorCatcher from 'components/GlobalErrorCatcher';
import Header from 'components/common/Header';
import Inventory from 'components/Inventory';
import Login from 'components/Login';
import NotFound from 'components/NotFound';
import OrderDetails from 'components/Orders/Details';
import Orders from 'components/Orders';
import PeplinkIdCallback from 'components/PeplinkIdCallback';
import React from 'react';
import ReactToast from 'components/common/ReactToast';
import ScrollToTop from 'components/common/ScrollToTop';
import Shipment from 'components/Shipment';
import ShipmentConfirm from 'components/Shipment/Confirm';
import ShipmentDetails from 'components/Shipment/Details';
import Sidebar from 'components/common/Sidebar';
import StockFulfillment from 'components/Orders/StockFulfillment';

class SidebarLayout extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div id="wrapper" className="d-flex">
          <Sidebar />
          <div id="content-wrapper">
            {this.props.children}
            <ReactToast />
          </div>
        </div>
      </>
    );
  }
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  return (
    <GlobalErrorCatcher>
      <Route
        {...rest}
        render={(props) => {
          return (
            <SidebarLayout>
              <Component {...props} />
            </SidebarLayout>
          );
        }}
      />
    </GlobalErrorCatcher>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path={`/${ROUTE.LOGIN}`} component={Login} />
        <Route path={`/${ROUTE.PEPLINKID_CALLBACK}`} component={PeplinkIdCallback} />
        <AuthenticatedRoute path={`/${ROUTE.INVENTORY}/:warehouse?`} component={Inventory} />
        <AuthenticatedRoute path={`/${ROUTE.ORDERS}/:warehouse/stock-fulfillment`} component={StockFulfillment} />
        <AuthenticatedRoute path={`/${ROUTE.ORDERS}/:warehouse/:id`} component={OrderDetails} />
        <AuthenticatedRoute exact path={`/${ROUTE.ORDERS}/:warehouse?`} component={Orders} />
        <AuthenticatedRoute path={`/${ROUTE.SHIPMENT}/:warehouse/confirm`} component={ShipmentConfirm} />
        <AuthenticatedRoute path={`/${ROUTE.SHIPMENT}/:warehouse/:id`} component={ShipmentDetails} />
        <AuthenticatedRoute path={`/${ROUTE.SHIPMENT}/:warehouse?`} component={Shipment} />
        <Redirect exact from="/" to={`/${ROUTE.LOGIN}?redir=true`} />
        <Route path={'/notFound'} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
