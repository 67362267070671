import { NavLink } from 'react-router-dom';
import { ROUTE } from 'definitions';
import React from 'react';

const Sidebar = () => {
  const currentWarehouse = window.location.pathname.split('/')[2];
  return (
    <div id="sidebar-wrapper">
      <div id="menu-wrapper">
        <div className="list-group list-group-flush">
          <NavLink to={`/${ROUTE.INVENTORY}/${currentWarehouse}`} className="list-group-item list-group-item-action">
            <i className="material-icons md-dark md-24">store</i>
            <span>Inventory</span>
          </NavLink>
          <NavLink to={`/${ROUTE.SHIPMENT}/${currentWarehouse}`} className="list-group-item list-group-item-action">
            <i className="material-icons md-dark md-24">airplanemode_active</i>
            <span>Consignment Shipment</span>
          </NavLink>
          <NavLink to={`/${ROUTE.ORDERS}/${currentWarehouse}`} className="list-group-item list-group-item-action">
            <i className="material-icons md-dark md-24">list_alt</i>
            <span>Orders</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
