import { mapProductName } from 'utils/mapper';
import BaseStore from './base';
import stores from './index';
class InventoryStore extends BaseStore {
  constructor(props) {
    super(props);
    this.api = props.api;
  }

  search = (payload) => {
    return new Promise((resolve, reject) => {
      this.Table.isLoading = true;
      this.api
        .search(payload)
        .then((res) => {
          this.Table.data = res;
          const skus = res.items.reduce((acc, value) => [...acc, value.sku], []);
          stores.productStore
            .search(skus)
            .then((res) => {
              this.Table.data.items = mapProductName(this.Table.data.items, res);
            })
            .catch((err) => {
              reject(err);
            });
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          this.Table.isLoading = false;
        });
    });
  };

  sns = (payload) => this.api.sns(payload);
  summary = () => {
    return this.api.summary();
  };
  inventory = () => this.api.inventory();
}

export default InventoryStore;
