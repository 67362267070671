import { get } from 'lodash';
import ControlledTable from './ControlledTable';
import isEqual from 'lodash/isEqual';
import React from 'react';
import styled from 'styled-components';
import Table from './Table';

const TableIndex = React.forwardRef(({ columns, data, updateMyData, skipReset, options }, ref) => {
  const defaultOptions = {
    perPageText: 'Rows Per Page',
    sortable: true,
    defaultSelected: [],
    ...options,
  };

  const UseTable = defaultOptions.fetchData ? ControlledTable : Table;
  const defaultPageSize = get(defaultOptions, 'filters.pageSize') || defaultOptions.defaultPageSize || 10;

  const defaultTableProps = React.useMemo(
    () => ({
      columns,
      data,
      disableSortBy: !defaultOptions.sortable,
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      initialState: {
        selectedRowIds: defaultOptions.defaultSelected,
        pageSize: defaultPageSize,
      },
      defaultColumn: {
        sortable: true,
        width: 20,
        Cell: ({ cell: { value } }) => (typeof value !== 'undefined' && value !== null ? String(value) : null),
      },

      updateMyData,
    }),
    [columns, data, defaultOptions.defaultSelected, defaultOptions.sortable, defaultPageSize, skipReset, updateMyData]
  );

  return (
    <TableComponentStyled className="table-responsive">
      <UseTable
        ref={ref}
        defaultTableProps={defaultTableProps}
        defaultOptions={defaultOptions}
        skipReset={skipReset}
        options={options}
      />
    </TableComponentStyled>
  );
});

const TableComponentStyled = styled.div`
  position: relative;
  min-height: 150px;
  th {
    .cursor-pointer {
      display: flex;
      .material-icons {
        min-height: 18px;
      }
    }
    &.text-center {
      .cursor-pointer {
        justify-content: center;
      }
    }
  }
  th,
  td {
    &.collapsed {
      width: 0.0000000001%;
    }
  }

  .table-footer {
    select,
    input {
      border: none;
    }
    input {
      transition: all 0.3s ease-out;
      width: 30px;
      text-align: center;
      &:focus {
        width: 50px;
      }
    }
    button:focus {
      box-shadow: none;
    }
  }
`;

export default React.memo(TableIndex, (prev, next) => isEqual(prev, next));
