import BaseStore from './base';

class OrderStore extends BaseStore {
  constructor(props) {
    super(props);
    this.api = props.api;
  }

  search = (payload) => this.api.search(payload);
}

export default OrderStore;
