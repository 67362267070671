import Modal from './Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import React from 'react';
import styled from 'styled-components';

const ReadSerialsModal = ({ onToggle, items, title = 'Assigned Serial Number(s)' }) => {
  return (
    <Modal onToggle={onToggle}>
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody>
        <ListStyled className="small">
          {items.map((i, key) => (
            <li key={key}>{i}</li>
          ))}
        </ListStyled>
      </ModalBody>
    </Modal>
  );
};

export default ReadSerialsModal;

const ListStyled = styled.ul`
  overflow: auto;
  border: 1px solid #efefef;
  list-style-type: none;
  padding: 0.5rem;
  margin: 0;
`;
