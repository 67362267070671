import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import React from 'react';

const ReactDropdown = ({ value = {}, onChange, options }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        color="default"
        id="dropdown-action"
        className="btn btn-dropdown dropdown-toggle d-flex align-items-center h-100 shadow-none"
        aria-haspopup={true}
        aria-expanded="false"
      >
        {value.label}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((i, key) => (
          <DropdownItem key={`dropdown-item-${key}`} onClick={() => onChange(i)}>
            {i.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ReactDropdown;
