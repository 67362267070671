import AuthServices from './auth';
import Http from './http';
import InventoryServices from './inventory';
import OrderServices from './order';
import ProductServices from './product';
import ReceivingServices from './receiving';
import SerialNumberServices from './serialNumber';
import StockServices from './stock';
const http = new Http();

export default {
  authServices: new AuthServices({ http }),
  inventoryServices: new InventoryServices({ http }),
  receivingServices: new ReceivingServices({ http }),
  orderServices: new OrderServices({ http }),
  productServices: new ProductServices({ http }),
  serialNumberServices: new SerialNumberServices({ http }),
  stockServices: new StockServices({ http }),
};
