import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { formatCurrency, formatDate, formatDateTime } from 'utils/formatters';
import { getDisabled, getSerialItems } from 'utils/helpers';
import { inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { SHIPMENT_STATUS } from 'definitions';
import { useForm } from 'react-hook-form';
import AssignQuantityModal from 'components/common/AssignQuantityModal';
import Comments from './Comments';
import ConfirmSerialModal from 'components/common/ConfirmSerialModal';
import CreateShipmentModal from 'components/Orders/CreateShipmentModal';
import Loading from 'components/common/Loading';
import moment from 'moment';
import React, { useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import ReadSerialsModal from 'components/common/ReadSerialsModal';
import TrackingInformation from './TrackingInformation';
import UpdateSerialModal from 'components/common/UpdateSerialModal';
import useToggleModal from 'utils/useToggleModal';

const OrderDetails = (props) => {
  const {
    orderStore,
    stockStore,
    orderStore: {
      CRUD: { data, isLoading },
    },
    match: { params },
    history,
  } = props;
  const [draftItems, setDraftItems] = useState([]);
  const [page, setPage] = useState({});
  const [selected, setSelected] = useState([]);
  const [readSerials, setReadSerials] = useState(undefined);
  const [tableData, setTableData] = useState([]);
  const [serialModalItems, setSerialModalItems] = useState([]);
  const ref = React.useRef();
  const methods = useForm();
  const { errors } = methods;
  const currentWarehouse = window.location.pathname.split('/')[2];

  React.useEffect(() => {
    setTableData(data.lines);
  }, [data.lines]);

  const summary = React.useMemo(
    () =>
      data.lines.reduce(
        (acc, value) => ({
          ...acc,
          total: acc.total + value.quantity,
          confirmed: acc.confirmed + value.confirmedQuantity,
        }),
        { total: 0, confirmed: 0 }
      ),
    [data.lines]
  );

  React.useEffect(() => {
    orderStore.find(params.id, true);
  }, [params, orderStore]);

  const { isOpen, setIsOpen, toggleModal } = useToggleModal({
    readSerialModal: false,
    confirmSerialModal: false,
    assignQtyModal: false,
    createShipmentModal: false,
    confirmReceiptModal: false,
  });

  const handleSaveCurrentStatus = (items, bulk) => {
    return new Promise((resolve, reject) => {
      const shipmentDrafts = !bulk
        ? (data.shipment_draft && data.shipment_draft.items.filter((i) => !items.find((item) => item.sku === i.sku))) ||
          []
        : [];
      const draftItems = [...items, ...shipmentDrafts];
      orderStore
        .updateOrderShipmentShipmentDraftRequest({
          id: params.id,
          items: draftItems,
        })
        .then(() => {
          orderStore.find(params.id, true);
          resolve();
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };

  const handleConfirmSerialModal = React.useCallback(
    (selected) => {
      setSelected(selected);
      if (selected.requiring_sn) {
        toggleModal('confirmSerialModal');
      } else {
        toggleModal('assignQtyModal');
      }
    },
    [toggleModal]
  );

  const handleReadSerialModal = React.useCallback(
    (selected) => {
      setReadSerials(selected.sns);
      toggleModal('readSerialModal');
    },
    [toggleModal]
  );

  const handleAssignStocksShowModal = async () => {
    await getStocks();
    toggleModal('confirmReceiptModal');
  };

  const getStocks = async () => {
    const skus = data.lines.reduce((acc, value) => [...acc, value.sku], []);
    const res = await stockStore.search({ skus }).catch((err) => {
      if (err.data.errors === 'Invalid path name' || err.data.errors === 'You are not allowed to access this resource')
        history.push('/notFound');
    });
    const items = getSerialItems(res.items, 'available_sns');
    const draftItems = getSerialItems((data.shipment_draft && data.shipment_draft.items) || []);
    const disabled = getDisabled(data).filter((i) => res.items.find((item) => i.sku === item.sku));
    const serialItems = [...disabled, ...items];

    const selected = draftItems.reduce((acc, value) => {
      return { ...acc, [serialItems.findIndex((item) => item.sn === value.sn && item.sku === value.sku)]: true };
    }, {});

    setSerialModalItems(serialItems);
    setDraftItems(selected);
  };

  const handleAssignQuantity = (payload, type) => {
    const items = [{ ...payload, sku: selected.sku, sns: [] }];
    switch (type) {
      case 'create_shipment':
        setPage((prevState) => ({ ...prevState, sns: items }));
        toggleModal('createShipmentModal');
        return Promise.resolve();
      default:
        return handleSaveCurrentStatus(items);
    }
  };

  const handleUpdateSerials = (payload) => {
    return new Promise((resolve, reject) => {
      if (!payload.items.length) return false;
      orderStore
        .update({
          id: data.id,
          carrier: payload.carrier,
          waybill: payload.waybill,
          delivery_date: moment().format(),
          items: payload.items.map(({ sku, quantity, sns }) => ({ sku, quantity, sns: sns || [] })),
        })
        .then(() => {
          orderStore.find(params.id, true);
          resolve();
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };

  const handleCreateShipment = (payload) => {
    return new Promise((resolve, reject) => {
      handleUpdateSerials({ id: params.id, items: [...page.sns], ...payload })
        .then(() => {
          setIsOpen((prevState) => ({
            ...prevState,
            createShipmentModal: false,
            confirmSerialModal: false,
            confirmReceiptModal: false,
          }));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleSaveCurrentStatusBulk = (form, setErrors, data) => {
    const quantities = data
      .filter((i) => i.quantity)
      .map(({ sku, quantity }) => {
        return { sku, quantity, sns: [] };
      });
    handleSaveCurrentStatus([...form.items, ...quantities], true)
      .then(() => {
        toggleModal('confirmReceiptModal');
      })
      .catch((err) => setErrors(err.message));
  };

  const getTotalProducts = (items) => {
    return items.reduce((acc, value) => {
      return acc + value.quantity;
    }, 0);
  };

  const handleGlobalFilter = (e) => {
    if (ref) {
      ref.current.setGlobalFilter(e.target.value);
    }
  };

  const orderId = data.id && data.id.substring(0, 8).toUpperCase();
  const status = (SHIPMENT_STATUS[data.status] && SHIPMENT_STATUS[data.status]) || {};
  const isDisabled =
    !status.value ||
    status.value === SHIPMENT_STATUS.COMPLETED.value ||
    status.value === SHIPMENT_STATUS.CANCELLED.value ||
    status.value === SHIPMENT_STATUS.PENDING_CANCEL.value;
  function insertIf(condition, ...elements) {
    return condition ? elements : [];
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Code',
        accessor: 'sku',
        width: 10,
      },
      {
        Header: 'Product Name',
        accessor: 'productName',
        width: 30,
      },
      {
        Header: 'Order Qty',
        accessor: 'quantity',
        className: 'text-center collapsed',
        width: 10,
      },
      {
        accessor: 'sns',
        className: 'd-none',
      },
      {
        Header: 'Fulfilled Qty',
        accessor: 'confirmedQuantity',
        className: 'text-center',
        width: 15,
        Cell: ({
          cell: {
            value,
            row: { original },
          },
        }) => (
          <React.Fragment>
            {original.quantity === value
              ? value
              : status.value !== SHIPMENT_STATUS.CANCELLED.value && (
                  <div className="badge-warn">
                    <i className="material-icons md-warn md-18">warning</i>{' '}
                    {`${value ? 'Partial Shipped' : 'Please Assign'}`}
                    {!!value && <span className="badge badge-qty">{`x${value}`}</span>}
                  </div>
                )}
          </React.Fragment>
        ),
      },
      ...insertIf(data.currency, {
        Header: 'Unit Price',
        accessor: 'unit_price',
        className: 'text-center',
        width: 10,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => formatCurrency(original.unit_price),
      }),
      ...insertIf(data.currency, {
        Header: 'Subtotal',
        accessor: 'subtotal',
        className: 'text-center',
        width: 10,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => formatCurrency(original.unit_price * original.quantity),
      }),
      {
        Header: ' ',
        className: 'collapsed',
        Cell: ({
          cell: {
            row: { id, original },
          },
        }) => (
          <div className="action-icons d-flex justify-content-end">
            {original.sns && (
              <React.Fragment>
                <button
                  className="material-icons md-dark md-24"
                  id={`assigned_sn-${id}`}
                  onClick={() => handleReadSerialModal(original)}
                >
                  search
                </button>
                <UncontrolledTooltip placement="top" target={`assigned_sn-${id}`}>
                  Assigned SN
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {original.sns ? (
              <React.Fragment>
                {!isDisabled && original.quantity !== original.sns.length ? (
                  <React.Fragment>
                    <button
                      className="material-icons md-dark md-24"
                      id={`assign_stocks-${id}`}
                      onClick={() => handleConfirmSerialModal(original)}
                    >
                      move_to_inbox
                    </button>
                    <UncontrolledTooltip placement="top" target={`assign_stocks-${id}`}>
                      Assign Stocks
                    </UncontrolledTooltip>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!isDisabled && (
                  <React.Fragment>
                    <button
                      className="material-icons md-dark md-24"
                      id={`assign_stocks-${id}`}
                      onClick={() => handleConfirmSerialModal(original)}
                    >
                      move_to_inbox
                    </button>
                    <UncontrolledTooltip placement="top" target={`assign_stocks-${id}`}>
                      Assign Stocks
                    </UncontrolledTooltip>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        ),
      },
    ],

    [data.currency, status.value, isDisabled, handleReadSerialModal, handleConfirmSerialModal]
  );

  const getMeaning = (selectedWarehouse) => {
    switch (selectedWarehouse) {
      case 'mdg':
        return 'MDG';
      case 'west-network':
        return 'West Network';
      case 'frontier-us':
        return 'Frontier US';
      case 'peplink-lt':
        return 'Peplink LT';
    }
    return selectedWarehouse;
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex min-vh-100">
          <Loading />
        </div>
      ) : (
        <div className="container-fluid p-md-5 p-3">
          <div className="breadcrumb">
            <h6>
              <Link to={`/inventory/${currentWarehouse}`}>Home</Link> {'>'}{' '}
              <Link to={`/orders/${currentWarehouse}`}>Orders</Link> {'>'} {`#${orderId}`}
            </h6>
          </div>
          <div className="head-wrapper">
            <div className="row">
              <div className="head-title col-xl-7 col-lg-7 col-md-6 col-6">
                <h4>{`#${orderId}`}</h4>
              </div>
              <div className="d-flex justify-content-end align-items-center col-xl-5 col-lg-5 col-md-6 col-6">
                <div className="label label_partial-assign mr-2">{status.label}</div>
                {!isDisabled && (
                  <UncontrolledDropdown>
                    <DropdownToggle nav className="p-0">
                      <button
                        className="btn-action-menu material-icons md-light md-36"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        more_vert
                      </button>
                    </DropdownToggle>
                    <DropdownMenu right className="p-0 border">
                      <DropdownItem onClick={handleAssignStocksShowModal}>Assign Stocks</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>
            </div>
          </div>

          <div className="main-panel mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm">
                  <div className="pb-3 px-4">
                    <div className="row">
                      {data.order_id && (
                        <div className="received-qty col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                          <div className="title">Order No.</div>
                          <div className="data">{data.order_id}</div>
                        </div>
                      )}
                      {data.ref_id && data.warehouse === 'MDG' && (
                        <div className="col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                          <div className="title">MDG Ref. ID</div>
                          <div className="data">{data.ref_id}</div>
                        </div>
                      )}
                      <div className="customer col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Customer Name</div>
                        <div className="data">{data.ship_to_name}</div>
                      </div>

                      <div className="customer-po col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Customer PO No.</div>
                        <div className="data">{data.consignment_po_number}</div>
                      </div>

                      <div className="received-qty col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Warehouse</div>
                        <div className="data">{getMeaning(currentWarehouse)}</div>
                      </div>
                      {data.currency && (
                        <div className="received-qty col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                          <div className="title">Currency</div>
                          <div className="data">{data.currency}</div>
                        </div>
                      )}
                      <div className="awb-no col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Total Order Quantity</div>
                        <div className="data">{getTotalProducts(data.lines)}</div>
                      </div>
                      <div className="expect-ship-date col-xl-2 col-lg-4 col-md-6 col-12 mt-xl-3 mt-lg-3 mt-md-3 mt-3">
                        <div className="title">Expected Ship Date</div>
                        <div className="data">{formatDate(data.expected_delivery_date)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="product-panel mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card shadow-sm">
                  <div className="p-3 px-4">
                    <div className="title">Products</div>
                    <input
                      type="text"
                      className="form-control border-0"
                      placeholder="Search"
                      onChange={handleGlobalFilter}
                    />
                    <ReactTable isLoading={isLoading} data={tableData} columns={columns} ref={ref} />
                    <div className="total-products">Total {getTotalProducts(tableData)} product(s)</div>
                    {/* <div className="products-subtotal text-right mt-3 mb-2 p-2">
                    Order Grand Total
                    <span className="price">USD 0.00</span>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="other-info mt-4">
            <div className="row d-flex">
              <div className="shipping-info align-content-stretch col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="card shadow-sm h-100">
                  <div className="p-3 px-4">
                    <div className="title">Shipping Information</div>
                    <div className="content">
                      <div className="address">
                        <strong className="name">{data.ship_to_name}</strong>
                        <br />
                        {data.ship_to_company}
                        <br />
                        {data.ship_to_address_line_one}
                        <br />
                        {data.ship_to_city}
                        <br />
                        {data.ship_to_state} {data.ship_to_postal_code}
                        <br />
                        {data.ship_to_country_code}
                      </div>
                      <div className="mt-2 phone">T: {data.ship_to_phone}</div>
                      <div className="email">
                        E: <a href={`mailto:${data.ship_to_email}`}>{data.ship_to_email}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shipping-method align-content-stretch col-xl-3 col-md-6 col-sm-12 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                <div className="card shadow-sm h-100">
                  <div className="p-3 px-4">
                    <div className="title">Shipping Method</div>
                    <div className="content">
                      <strong className="method">{data.shipping_method && data.shipping_method.method}</strong>
                      <div className="carrier mt-2">{data.shipping_method && data.shipping_method.account}</div>
                      <div className="service">{data.shipping_method && data.shipping_method.service}</div>
                      <div className="insurance-info">
                        {data.shipping_method &&
                          data.shipping_method.customer_shipping_insurance_price &&
                          data.shipping_method.customer_shipping_insurance_currency && (
                            <>
                              <div>Shipping Insurance Required</div>
                              <div>
                                Declare Value: {data.shipping_method.customer_shipping_insurance_currency}{' '}
                                {data.shipping_method.customer_shipping_insurance_price}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="remarks align-content-stretch col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt-xl-0 mt-4">
                <div className="card shadow-sm h-100">
                  <div className="p-3 px-4">
                    <div className="title">Tracking Information</div>
                    <div className="content tracking-no">
                      <TrackingInformation items={data.confirmed_shipments} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="remarks align-content-stretch col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mt-xl-0 mt-4">
                <div className="card shadow-sm h-100">
                  <div className="p-3 px-4">
                    <div className="title">Remarks</div>
                    <div className="content">{data.consignment_remark}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="footer" className="row my-5">
            <div className="created-date d-flex align-items-end col-6">
              Created on {formatDateTime(data.created_date)}
            </div>
            <div className="confirm-action d-flex justify-content-end col-6">
              {!isDisabled && (
                <button className="btn btn-primary btn-assign" onClick={handleAssignStocksShowModal}>
                  Assign Stocks
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {isOpen.confirmReceiptModal && (
        <UpdateSerialModal
          isOrder
          status={data.status}
          methods={methods}
          items={data.lines}
          title={`AWB #${orderId}`}
          data={serialModalItems}
          draftItems={draftItems}
          remaining={summary.total - summary.confirmed}
          onDiscard={getStocks}
          onToggle={() => toggleModal('confirmReceiptModal')}
          actions={(form, setErrors, handleDiscard, data) => {
            const isDisabled =
              !isEmpty(errors) ||
              form.items.reduce((acc, value) => acc + value.quantity, 0) > summary.total - summary.confirmed;

            return (
              <React.Fragment>
                <Button
                  color="dark"
                  onClick={handleDiscard}
                  disabled={!page.query && page.selected && !page.selected.length}
                >
                  Reset
                </Button>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle nav className="p-0">
                    <button className="btn btn-primary dropdown-toggle">Assign Stocks</button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      // disabled={isDisabled}
                      onClick={() => {
                        const quantities = data
                          .filter((i) => i.quantity)
                          .map(({ sku, quantity }) => {
                            return { sku, quantity, sns: [] };
                          });
                        setPage((prevState) => ({ ...prevState, sns: [...form.items, ...quantities] }));
                        toggleModal('createShipmentModal');
                      }}
                    >
                      Create Shipment
                    </DropdownItem>
                    <DropdownItem
                      disabled={isDisabled}
                      onClick={() => handleSaveCurrentStatusBulk(form, setErrors, data)}
                    >
                      Save Current Status
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </React.Fragment>
            );
          }}
        />
      )}

      <Comments />

      {isOpen.readSerialModal && (
        <ReadSerialsModal items={readSerials} onToggle={() => toggleModal('readSerialModal')} />
      )}
      {isOpen.confirmSerialModal && (
        <ConfirmSerialModal
          onToggle={() => toggleModal('confirmSerialModal')}
          submitText="Assign Stocks"
          data={data}
          selected={selected}
          actions={(form, setErrors) => {
            const sns =
              form.sns &&
              form.sns
                .split('\n')
                .map((i) => i.trim())
                .filter((i) => i);

            return (
              <>
                <Button color="dark" onClick={() => toggleModal('confirmSerialModal')}>
                  Reset
                </Button>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle nav className="p-0">
                    <button disabled={!form.sns} className="btn btn-primary dropdown-toggle">
                      Assign Stocks
                    </button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => {
                        setPage((prevState) => ({
                          ...prevState,
                          sns: [{ sns, quantity: sns.length, sku: selected.sku }],
                        }));
                        toggleModal('createShipmentModal');
                      }}
                    >
                      Create Shipment
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleSaveCurrentStatus([{ sns, quantity: sns.length, sku: selected.sku }])
                          .then(() => {
                            toggleModal('confirmSerialModal');
                          })
                          .catch((err) => setErrors(err.message));
                      }}
                    >
                      Save Current Status
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            );
          }}
        />
      )}

      {isOpen.assignQtyModal && (
        <AssignQuantityModal
          actions={(form, handleSubmit) => (
            <>
              <Button color="dark" onClick={() => toggleModal('assignQtyModal')}>
                Reset
              </Button>
              <UncontrolledDropdown direction="up">
                <DropdownToggle nav className="p-0">
                  <button className="btn btn-primary dropdown-toggle" disabled={form.quantity === undefined}>
                    Assign Stocks
                  </button>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => handleSubmit('create_shipment')}>Create Shipment</DropdownItem>
                  <DropdownItem onClick={() => handleSubmit('save_current_status')}>Save Current Status</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
          selected={selected}
          remaining={selected.quantity - selected.confirmedQuantity}
          data={data}
          onSubmit={handleAssignQuantity}
          onToggle={() => toggleModal('assignQtyModal')}
        />
      )}
      {isOpen.createShipmentModal && (
        <CreateShipmentModal
          data={data}
          onSubmit={handleCreateShipment}
          onToggle={() => toggleModal('createShipmentModal')}
        />
      )}
    </>
  );
};

export default inject('orderStore', 'stockStore')(observer(OrderDetails));
