export default class BaseServices {
  constructor(props) {
    this.http = props.http;
    this.url = props.url;
  }
  search = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/search/${currentWarehouse}`, payload);
  };

  find = (id) => {
    return this.http.get(`${this.url}/${id}`);
  };

  update = (payload) => {
    const { id, ...rest } = payload;

    return this.http.put(`${this.url}/${id}`, rest);
  };
}
