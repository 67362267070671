import { inject } from 'mobx-react';
import { ROUTE } from 'definitions';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';

const Login = ({ history, authStore }) => {
  const [errors, setErrors] = useState([]);

  let peplinkLoginButton = {
    display: 'flex',
    width: '300px',
    height: '35px',
    alignItems: 'center',
    gap: '10px',
    padding: '1px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: 'white',
    border: '1px solid rgba(0,0,0,0.6)',
    borderRadius: '4px ',
  };
  let peplinkLoginText = {
    fontWeight: '600',
    fontSize: '13px',
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0',
    marginBottom: '0rem',
  };
  React.useEffect(() => {
    const { error } = qs.parse(history.location.search);
    if (error) {
      const { errors = {} } = JSON.parse(sessionStorage.getItem('AUTH_ERROR'));
      setErrors([errors.message]);
      sessionStorage.removeItem('AUTH_ERROR');
    }
  }, [history.location.search]);

  useEffect(() => {
    if (authStore.getToken()) {
      authStore.profile().then((res) => {
        const warehouseList = res.warehouse.split(', ');
        authStore.accessibleWarehouse = warehouseList;
        if (warehouseList.length > 1) {
          history.push(`/${ROUTE.INVENTORY}/peplink-lt`);
        } else {
          history.push(`/${ROUTE.INVENTORY}/${getPathName(warehouseList[0])}`);
        }
      });
    }
  }, [authStore, history]);

  const handlePeplinkLogin = () => {
    let peplinkIdHerf =
      process.env.REACT_APP_API_PEPLINKID_HOST +
      process.env.REACT_APP_API_PEPLINKID_PATH +
      '?client_id=' +
      process.env.REACT_APP_API_PEPLINKID_CLIENT_ID;
    window.location.href =
      peplinkIdHerf +
      '&response_type=token&redirect_uri=' +
      process.env.REACT_APP_API_PEPLINKID_REDIRECT_URI +
      '/peplinkid/callback&state=PEPLINK_PP2_LOGIN';
  };

  const getPathName = (warehouse) => {
    switch (warehouse) {
      case 'MDG':
        return 'mdg';
      case 'West Network':
        return 'west-network';
      case 'Frontier US':
        return 'frontier-us';
      case 'Peplink LT':
        return 'peplink-lt';
    }
    return warehouse;
  };

  return (
    <div className="container-fluid">
      <div className="panel-login card shadow-sm">
        <div className="m-4">
          <div className="logo mt-1">
            <div className="peplink-logo">
              <img className="peplink-image" src="images/logo_peplink.png" alt="" />
              <p className="yellow-consignment">Consignment</p>
            </div>
            <p className="sign-in m-4">Please Sign In</p>
            {!!errors.length && (
              <div className="alert alert-danger" role="alert">
                {errors.join('')}
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button style={peplinkLoginButton} onClick={handlePeplinkLogin}>
                <img
                  src="/images/favicon.png"
                  alt="Peplink Login"
                  border="0"
                  width="31"
                  height="31"
                  style={{ backgroundColor: 'white', borderRadius: '2px ' }}
                />
                <p style={peplinkLoginText}>Sign in with Peplink ID</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright m-2">
        <p>© 2024 Peplink. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default inject('authStore')(withRouter(Login));
