import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip } from 'reactstrap';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'definitions';
import { withRouter } from 'react-router-dom';
import React, { useState } from 'react';

const Header = (props) => {
  const node = React.useRef();
  const { authStore, history } = props;
  const [isOpen, setIsOpen] = useState({
    dropdownOpen: false,
  });
  const currentWarehouse = window.location.pathname.split('/')[2];

  React.useEffect(() => {
    history.listen(() => {
      node.current && node.current.classList.remove('is-menu');
    });
  });

  React.useEffect(() => {
    const paths = window.location.pathname.split('/');
    const isMatched = ['inventory', 'shipment', 'orders'].includes(paths[1]);
    authStore
      .profile()
      .then((res) => {
        let warehouseList = res.warehouse.split(', ');
        authStore.accessibleWarehouse = warehouseList;
        if (isMatched && (paths[2] === undefined || paths[2] === '')) {
          if (warehouseList.length > 1) {
            history.push(`/${paths[1]}/peplink-lt`);
          } else {
            history.push(`/${paths[1]}/${getPathName(warehouseList[0])}`);
          }
        }
      })
      .catch((err) => {
        if (err.data.errors === 'You are not allowed to access this resource')
          history.push(`/${ROUTE.LOGIN}?redir=true`);
      });
  }, [authStore, history]);

  const toggle = (toggle) => {
    setIsOpen({
      ...isOpen,
      [toggle]: !isOpen[toggle],
    });
  };

  const handleLogout = () => {
    authStore.logout();
    history.push(`/${ROUTE.LOGIN}`);
  };

  const handleWarehouseSwitch = (warehouse) => {
    history.push(`/${location.pathname.split('/')[1]}/` + getPathName(warehouse));
  };

  const getPathName = (warehouse) => {
    switch (warehouse) {
      case 'MDG':
        return 'mdg';
      case 'West Network':
        return 'west-network';
      case 'Frontier US':
        return 'frontier-us';
      case 'Peplink LT':
        return 'peplink-lt';
    }
    return warehouse;
  };

  return (
    <header className="fixed-top">
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <div className="peplink-logo" style={{ justifyContent: 'flex-start', marginBottom: '20px' }}>
              <img className="peplink-image" src="/images/logo_peplink.png" alt="" />
              <p className="yellow-consignment">Consignment</p>
            </div>
          </Link>
        </div>
        <div id="" className="d-flex justify-content-end align-items-center ml-auto">
          <div className="quick-btn">
            <Link id="confirm_shipment" to={`/shipment/${currentWarehouse}/confirm`}>
              <i className="i-confirm-receipt material-icons md-dark md-24 p-0">move_to_inbox</i>
            </Link>

            <Link id="stock_fulfillment" to={`/orders/${currentWarehouse}/stock-fulfillment`}>
              <i className="i-stock-fulfill material-icons md-dark md-24 pl-3 pr-0">move_to_inbox</i>
            </Link>
            <UncontrolledTooltip placement="bottom" target="confirm_shipment">
              Confirm Shipment
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target="stock_fulfillment">
              Stock Fulfillment
            </UncontrolledTooltip>
          </div>
          <Dropdown id="user-id" className="user" isOpen={isOpen.dropdownOpen} toggle={() => toggle('dropdownOpen')}>
            <DropdownToggle
              className="d-flex align-items-center"
              tag="div"
              data-toggle="dropdown"
              aria-expanded={isOpen.dropdownOpen}
              caret
            >
              {/* <div className="profile-image float-left"></div> */}
              {/* <div className="greeting px-2 d-none d-sm-block">Hi, {authStore.user.user_name}</div> */}
              Hi, {authStore.user.user_name}
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem>Profile</DropdownItem> */}
              {/* <DropdownItem onClick={() => bugsnagClient.notify(new Error('Test error'))}>Generate Error</DropdownItem> */}
              {authStore.accessibleWarehouse?.length > 1 && (
                <>
                  {authStore.accessibleWarehouse.map((option) => (
                    <DropdownItem key={option} onClick={() => handleWarehouseSwitch(option)}>
                      {option}
                    </DropdownItem>
                  ))}
                </>
              )}
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </nav>
    </header>
  );
};

export default inject('authStore')(withRouter(observer(Header)));
