import { Button, ModalBody, ModalFooter, ModalHeader, Modal as ReactModal } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';

const ConfirmDialog = ({ onConfirm, onClose, message }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [errors, setErrors] = React.useState();

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 100);
  };
  const handleConfirm = () => {
    onConfirm()
      .then(() => {
        setIsOpen(false);
        setTimeout(() => {
          onClose();
        }, 100);
      })
      .catch((err) => setErrors(err));
  };
  return (
    <ConfirmDialogStyled isOpen={isOpen} toggle={handleClose}>
      <ModalHeader>
        <i className="material-icons md-20 p-0 pr-1">error_outline</i> Warning
      </ModalHeader>
      <ModalBody className="py-0">
        {errors && <p className="alert alert-danger">{errors}</p>}

        {message}
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" color="link" onClick={handleConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </ConfirmDialogStyled>
  );
};

export default ConfirmDialog;

const ConfirmDialogStyled = styled(ReactModal)`
  align-items: flex-start;
  justify-content: flex-end;
  margin: unset;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  width: 450px;
  .modal-content {
    background-color: #df4626;
    color: #fff;
    font-size: 14px;
  }
  .modal-header {
    padding: 12px 16px;
  }
  .modal-title {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .material-icons {
      font-size: 22px;
    }
  }
  .modal-footer {
    button {
      transition: all 0.3s ease-in;
      padding: 0.25rem 0.5rem;
      color: #fff;
      &:hover {
        color: #ccc;
      }
    }
  }
`;
