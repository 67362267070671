import Modal from 'components/common/DEPRECATED/Modal';
import React from 'react';
import styled from 'styled-components';

const ConfirmSerialModal = ({ data = {}, selected = {}, onToggle, onSubmit, submitText, actions }) => {
  const [form, setForm] = React.useState({ sns: '' });
  const [errors, setErrors] = React.useState();
  React.useEffect(() => {
    const found = data.shipment_draft && data.shipment_draft.items.find((item) => item.sku === selected.sku);
    if (found) {
      setForm({ sns: found.sns.join('\n') });
    }
  }, [data, selected.sku]);
  const handleSubmit = () => {
    onSubmit(form)
      .then(onToggle)
      .catch((err) => {
        setErrors(err.message);
      });
  };
  return (
    <Modal
      title={
        <span>
          Serial Number(s){' '}
          <small>
            <i>(one per line)</i>
          </small>
        </span>
      }
      actions={() => (actions ? actions(form, setErrors) : null)}
      isOpen={true}
      onToggle={onToggle}
      onSubmit={handleSubmit}
      submitText={submitText}
      cancelText="Reset"
      disabled={!form.sns}
    >
      {errors && (
        <div className="alert alert-danger">
          <ListStyled>
            <li>{errors}</li>
          </ListStyled>
        </div>
      )}

      <textarea
        value={form.sns}
        onChange={(e) =>
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          })
        }
        className="form-control"
        name="sns"
        id="sn-box"
        rows="10"
      ></textarea>
    </Modal>
  );
};

export default ConfirmSerialModal;

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
