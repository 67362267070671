import { inject, observer } from 'mobx-react';
import { ROUTE } from 'definitions';
// import axios from 'axios';
import Loading from './common/Loading';
import React from 'react';

const PeplinkIdCallback = ({ history, authStore }) => {
  React.useEffect(() => {
    let token = history.location.hash
      .split('token')[1]
      .slice(0, -1)
      .slice(1);

    if (token) {
      authStore
        .login({
          peplink_id_token: token,
        })
        .then(() => {
          const REDIRECT = sessionStorage.getItem('REDIRECT');
          if (REDIRECT) {
            history.push(REDIRECT);
            sessionStorage.removeItem('REDIRECT');
          } else {
            if (authStore.accessibleWarehouse.length > 1) {
              history.push(`/${ROUTE.INVENTORY}/peplink-lt`);
            } else {
              history.push(`/${ROUTE.INVENTORY}/${getPathName(authStore.accessibleWarehouse[0])}`);
            }
          }
        })
        .catch((err) => {
          sessionStorage.setItem('AUTH_ERROR', JSON.stringify(err.data));
          history.push(`/${ROUTE.LOGIN}?error=true`);
        });
    }
  }, [authStore, history]);

  const getPathName = (warehouse) => {
    switch (warehouse) {
      case 'MDG':
        return 'mdg';
      case 'West Network':
        return 'west-network';
      case 'Frontier US':
        return 'frontier-us';
      case 'Peplink LT':
        return 'peplink-lt';
    }
    return warehouse;
  };

  return <Loading />;
};

export default inject('authStore')(observer(PeplinkIdCallback));
