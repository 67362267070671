import { makeObservable, observable } from 'mobx';

class CommonStore {
  constructor() {
    makeObservable(this, {
      toasters: observable,
    });
  }
  toasters = [];
}

export default CommonStore;
