import { Link } from 'react-router-dom';
import React from 'react';

export default () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column vh-100 text-center">
      <Link to="/">
        <div className="peplink-logo" style={{ marginBottom: '1.5rem' }}>
          <img className="peplink-image" src="images/logo_peplink.png" alt="" />
          <p className="yellow-consignment">Consignment</p>
        </div>
      </Link>

      <img alt="error" width="94" src="/images/error_404.svg" className="mb-4" />
      <h2>Page Not Found</h2>
      <div className="small mb-4">The requested page was not found</div>
      <a href="/" to="/" className="btn btn-primary mb-5">
        Home
      </a>
    </div>
  );
};
