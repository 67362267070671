/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

export default () => {
  return <LoadingStyled />;
};

const LoadingStyled = styled.div`
  background: rgba(255, 255, 255, 0.6);
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto; background: none; display: block; shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='%237f7f7f' stroke-width='3' r='10' stroke-dasharray='47.12388980384689 17.707963267948966' transform='rotate(224.716 50 50)'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' values='0 50 50;360 50 50' keyTimes='0;1'%3E%3C/animateTransform%3E%3C/circle%3E%3C!-- %5Bldio%5D generated by https://loading.io/ --%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
