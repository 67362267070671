import InputWithClearButton from './InputWithClearButton';
import React from 'react';
import styled from 'styled-components';
import useDebounce from 'utils/useDebounce';

const SearchForm = ({ onChange, children }) => {
  const { value, setValue } = useDebounce({ onChange });
  return (
    <SearchFormStyled className="input-group mb-3">
      {children}
      <InputWithClearButton onChange={setValue} value={value} />
      <div className="input-group-append">
        <span className="input-group-text">
          <i className="material-icons md-dark md-22">search</i>
        </span>
      </div>
    </SearchFormStyled>
  );
};

export default SearchForm;

const SearchFormStyled = styled.div`
  &.input-group button.btn-clear {
    position: absolute;
    right: 4rem;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 10;
  }
`;
