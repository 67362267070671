import { isArray } from 'lodash';
import { Modal } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import Button from 'reactstrap/lib/Button';
import cx from 'classnames';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import React from 'react';
import styled from 'styled-components';

const CreateShipmentModal = (props) => {
  const methods = useForm();
  const { register, handleSubmit, setValue } = methods;
  const { onToggle, onSubmit, data = {} } = props;
  const [error, setError] = React.useState([]);

  const onSave = ({ waybill }) => {
    const payload = {
      waybill,
    };
    onSubmit(payload)
      .then(() => onToggle())
      .catch((err) => {
        setError(isArray(err.message) ? err.message : [err.message]);
      });
  };

  const isPickup = data.shipping_method && data.shipping_method.method === 'Self Pickup';

  return (
    <Modal>
      <ModalHeader toggle={onToggle}>CREATE SHIPMENT</ModalHeader>
      <ModalBody>
        {!!error.length && (
          <div className="alert alert-danger">
            <ListStyled>
              {error.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ListStyled>
          </div>
        )}

        <div className="modal-description">Please input the relevant carrier information for shipment.</div>
        <div id="awb-info" className="form-group">
          <div className="row">
            <div className={cx('small', { 'col-4': !isPickup }, { 'col-6': isPickup })}>
              <label className="form-title" htmlFor="carrier">
                Carrier
              </label>
              <div>{data.shipping_method && data.shipping_method.method}</div>
            </div>
            <div className={cx('small', { 'col-4': !isPickup }, { 'col-6': isPickup })}>
              <label className="form-title" htmlFor="carrier">
                Service
              </label>
              <div>{data.shipping_method && data.shipping_method.service}</div>
            </div>
            {!isPickup && (
              <div className="col-4">
                <label className="form-title" htmlFor="awb">
                  Airway Bill No.
                </label>
                <input
                  type="text"
                  className="form-control awb-number border"
                  maxLength="25"
                  id="awb"
                  name="waybill"
                  aria-describedby="AWB No."
                  ref={register({ required: true })}
                  onChange={(e) => setValue('waybill', e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="dark" onClick={onToggle}>
          Cancel
        </Button>
        <Button type="submit" color="primary" onClick={handleSubmit(onSave)}>
          Create Shipment
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateShipmentModal;

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
