const moment = require('moment');

export const formatDate = (date) => {
  return date ? moment(date).format('D MMM YYYY') : '';
};

export const formatDateTime = (date) => {
  return date ? moment(date).format('D MMM YYYY HH:MM') : '';
};

export const formatCurrency = (value) => value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
