import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import React from 'react';

class GlobalErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, info: null, hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
      hasError: true,
    });
  }
  report = () => {
    const { authStore } = this.props;
    const { error, info } = this.state;
    const email = process.env.REACT_APP_SUPPORT_EMAIL;
    const subject = 'Error Reporting';
    const divider = '------------------------------------------------';
    const br = '%0D%0A';
    const thisBody = `(Please don't change any text below this line)
        ${br}
        ${divider}
        ${br}
        User: ${JSON.stringify(authStore.user)}
        ${br}
        ${divider}
        ${br}
        URL: ${JSON.stringify(window.location.href)}
        ${br}
        ${divider}
        ${br}
        Error: ${error.message}
        ${br}
        ${divider}
        ${br}
        Stack Trace: ${JSON.stringify(info)}
        `;
    return `mailto:${email}?subject=${subject}&body=${thisBody}`;
  };

  render() {
    return this.state.hasError ? (
      <div className="d-flex justify-content-center align-items-center flex-column vh-100 text-center">
        <Link to="/">
          <div className="peplink-logo">
            <img className="peplink-image" src="images/logo_peplink.png" alt="" />
            <p className="yellow-consignment">Consignment</p>
          </div>
        </Link>

        <img alt="error" width="94" src="/images/error_404.svg" className="mb-4" />
        <h2>
          Whoopsie! <br /> Something Went Wrong.
        </h2>
        <div className="small mb-4">
          Errors were found, please refresh the page. <br /> If errors persist, please kindly report it.
        </div>
        <a href={this.report()} className="btn btn-primary mb-5">
          Let's Report!
        </a>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default inject('authStore')(GlobalErrorCatcher);
