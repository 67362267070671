import React from 'react';

export default () => {
  const [selectedDisabled, setSelectedDisabled] = React.useState([]);

  const onChangeDisabled = (value, item) => {
    let selected = selectedDisabled;
    if (value && value !== 0) {
      if (selectedDisabled.indexOf(item) === -1) {
        selected = [...selectedDisabled, item];
      }
    } else {
      selected = selectedDisabled.filter((s) => s !== item);
    }
    setSelectedDisabled(selected);
  };

  const filterDisabled = React.useCallback((data) => {
    setSelectedDisabled(data.filter((i) => i.disabled && i.quantity > 0));
  }, []);

  return {
    selectedDisabled,
    onChangeDisabled,
    filterDisabled,
  };
};
