import { inject } from 'mobx-react';
import Modal from 'components/common/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import styled from 'styled-components';

const SerialModal = ({ sku, stockStore, onToggle, history }) => {
  const [serials, setSerials] = React.useState([]);
  React.useEffect(() => {
    stockStore
      .search({
        skus: [sku],
      })
      .then((res) => {
        const data = res.items
          .reduce(
            (acc, { available_sns, reserved_sns }) => [
              ...acc,
              ...(available_sns || []),
              ...(reserved_sns || []).map((sn) => sn + ' (Assigned)'),
            ],
            []
          )
          .map((i) => ({
            sn: i,
          }));
        setSerials(data);
      })
      .catch((err) => {
        if (
          err.data.errors === 'Invalid path name' ||
          err.data.errors === 'You are not allowed to access this resource'
        )
          history.push('/notFound');
      });
  }, [stockStore, setSerials, sku, history]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Serial No.',
        accessor: 'sn',
        width: 20,
      },
    ],

    []
  );
  return (
    <Modal onToggle={onToggle}>
      <ModalHeader toggle={onToggle}>Serial Number(s)</ModalHeader>
      <ModalBody>
        {serials.length ? (
          <ReactTableStyled data={serials} columns={columns} options={{ perPageText: 'Records Per Page' }} />
        ) : (
          <small className="min-vh-10">Serial Number is not applicable on this product.</small>
        )}
      </ModalBody>
    </Modal>
  );
};

export default inject('stockStore')(SerialModal);

const ReactTableStyled = styled(ReactTable)`
  .form-control {
    border-bottom: 1px solid #c9c9c9;
    margin-bottom: 1rem;
  }
  .table-responsive {
    border: 1px solid #efefef;
    padding: 0.5rem;
    margin-bottom: 1rem;
    table {
      font-size: 13px;
      margin-bottom: 0;
      thead {
        display: none;
      }
      tbody {
        tr:hover,
        tr:nth-of-type(odd) {
          background-color: inherit;
        }
        td {
          padding: 0;
        }
      }
    }
  }
`;
