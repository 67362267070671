import { SHIPMENT_STATUS } from 'definitions';
import React from 'react';

const TableStatus = ({ type, text }) => {
  switch (type) {
    case SHIPMENT_STATUS.PENDING_RECEIVE.value:
      return (
        <label className="label label-warn label-status status-pending">
          <i className="material-icons md-18 pl-0 pr-1">error_outline</i>
          {SHIPMENT_STATUS.PENDING_RECEIVE.label}
        </label>
      );
    case SHIPMENT_STATUS.PENDING_SHIPPED.value:
    case SHIPMENT_STATUS.PENDING_SHIPMENT.value:
    case SHIPMENT_STATUS.PENDING_CANCEL.value:
      return (
        <label className="label label-warn label-status status-pending">
          <i className="material-icons md-18 pl-0 pr-1">error_outline</i>
          {SHIPMENT_STATUS[type].label}
        </label>
      );
    case SHIPMENT_STATUS.PARTIAL_RECEIVED.value:
    case SHIPMENT_STATUS.PARTIAL_SHIPPED.value:
      return <label className="label label-status status-partial">{SHIPMENT_STATUS[type].label}</label>;
    case SHIPMENT_STATUS.CANCELLED.value:
      return <label className="label label-status status-cancelled">{SHIPMENT_STATUS.CANCELLED.label}</label>;
    default:
      return <label className="label label-status status-completed">{text || SHIPMENT_STATUS[type].label}</label>;
  }
};

export default TableStatus;
