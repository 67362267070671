import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import Modal from 'components/common/DEPRECATED/Modal';
import NumberInput from './NumberInput';
import React from 'react';

const AssignQuantityModal = ({
  actions,
  selected,
  data,
  onToggle,
  onSubmit,
  label = 'Assign Quantity',
  submitText = 'Update',
  remaining,
}) => {
  const [form, setForm] = React.useState({});
  const methods = useForm();
  const { register, setValue, errors } = methods;
  const [error, setError] = React.useState();
  const handleSubmit = (type) => {
    onSubmit(form, type)
      .then(() => {
        onToggle();
      })
      .catch((err) => setError(err.message));
  };

  const found = React.useMemo(() => {
    return (data.shipment_draft && data.shipment_draft.items.find((item) => item.sku === selected.sku)) || {};
  }, [data.shipment_draft, selected.sku]);

  React.useEffect(() => {
    setForm((prevState) => ({ ...prevState, quantity: found.quantity }));
  }, [data.shipment_draft, found.quantity, selected.sku]);
  const name = 'quantity';
  return (
    <Modal
      size="sm"
      actions={() => (actions ? actions(form, handleSubmit) : null)}
      id="AssignQty"
      title={selected.sku}
      submitText={submitText}
      onToggle={onToggle}
      onSubmit={handleSubmit}
    >
      {error && <p className="alert alert-danger m-0">{error}</p>}

      <div className="input-group pt-3">
        <div className="input-group-prepend">
          <span className="input-group-text">{label}</span>
        </div>
        <NumberInput
          name={name}
          value={found.quantity}
          ref={register({
            min: {
              value: 0,
              message: `This field is required`,
            },
            max: {
              value: remaining,
              message: `This field is invalid`,
            },
          })}
          className="form-control received-qty text-right"
          onChange={(e) => {
            const {
              target: { value, name },
            } = e;
            setValue(name, value, { shouldValidate: true });
            setForm((prevState) => ({ ...prevState, quantity: value }));
          }}
        />
      </div>
      <small className="text-danger">
        <ErrorMessage errors={errors} name={name}>
          {({ messages }) => {
            return messages && Object.entries(messages).map(([type, message]) => <li key={type}>{message}</li>);
          }}
        </ErrorMessage>
      </small>
    </Modal>
  );
};

export default AssignQuantityModal;
