import { makeObservable, observable } from 'mobx';

class BaseStore {
  constructor(props) {
    makeObservable(this, {
      Table: observable,
      CRUD: observable,
    });
    this.api = props.api;
  }
  Table = {
    isLoading: false,
    data: {
      items: [],
    },
  };

  CRUD = {
    isLoading: false,
    data: { lines: [] },
  };

  search = ({ page_num = 0, page_size = 10, ...rest }) => {
    return new Promise((resolve, reject) => {
      this.Table.isLoading = true;
      this.api
        .search({ ...rest, page_size, page_num })
        .then((res) => {
          this.Table.data = res;
          resolve(res);
        })
        .catch((err) => reject(err))
        .finally(() => {
          this.Table.isLoading = false;
        });
    });
  };

  find = (id) => {
    return new Promise((resolve, reject) => {
      this.CRUD.isLoading = true;
      this.api
        .find(id)
        .then((res) => {
          this.CRUD.data = res;
          resolve(res);
        })
        .catch((err) => reject(err))
        .finally(() => {
          this.CRUD.isLoading = false;
        });
    });
  };

  update = (payload) => {
    return new Promise((resolve, reject) => {
      this.CRUD.isLoading = true;
      this.api
        .update(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err))
        .finally(() => {
          this.CRUD.isLoading = false;
        });
    });
  };
}

export default BaseStore;
