import Modal from 'components/common/DEPRECATED/Modal';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import useToggleModal from 'utils/useToggleModal';

const TRACKING_URL = {
  'FedEx': 'https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=',
  'UPS': 'https://www.ups.com/track?requester=WT/trackdetails&tracknum=',
  'UPS Standard': 'https://www.ups.com/track?requester=WT/trackdetails&tracknum=',
  'UPS Express': 'https://www.ups.com/track?requester=WT/trackdetails&tracknum=',
};
const TrackingInformation = ({ items = [] }) => {
  const [selected, setSelected] = React.useState({ items: [] });
  const [tableData, setTableData] = React.useState([]);
  const { isOpen, toggleModal } = useToggleModal({
    itemsModal: false,
  });

  React.useEffect(() => {
    let items = [];
    selected.items.forEach((item) => {
      if (item.sns.length) {
        item.sns.forEach((sn) => {
          items.push({
            sku: item.sku,
            sn,
            quantity: 1,
          });
        });
      } else {
        items.push(item);
      }
    });
    setTableData(items);
  }, [selected.items]);

  const handleClick = (e, data) => {
    e.preventDefault();
    setSelected(data);
    toggleModal('itemsModal');
  };

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Serial No.',
        accessor: 'sn',
      },
      {
        Header: 'Product Code',
        accessor: 'sku',
      },
      {
        Header: 'Received Quantity',
        accessor: 'quantity',
        className: 'text-center',
        width: 10,
      },
    ];
  }, []);

  return (
    <div>
      {items.map((i, key) => {
        const trackingUrl = TRACKING_URL[i.carrier];

        return (
          <div key={`tracking-information-${key}`} className="d-flex">
            {trackingUrl ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${trackingUrl}${i.waybill}`}
                className="btn-clear text-primary"
              >{`${i.carrier}: ${i.waybill}`}</a>
            ) : (
                <span>{`${i.carrier}`}{i.waybill ? `: ${i.waybill}` : ``}</span>
            )}

            <button onClick={(e) => handleClick(e, i)} className="btn-clear ml-auto">
              View Items
            </button>
          </div>
        );
      })}
      {isOpen['itemsModal'] && (
        <Modal
          size="lg"
          actions={() => <></>}
          cancelText="Close"
          title={selected.waybill ? `${selected.carrier} #${selected.waybill}` : `${selected.carrier}`}
          isOpen={isOpen}
          onToggle={() => toggleModal('itemsModal')}
        >
          <ReactTable data={tableData} columns={columns} />
        </Modal>
      )}
    </div>
  );
};
export default TrackingInformation;
