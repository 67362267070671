import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';

const StatusFilter = ({ pathname, type = { value: '', label: 'All' }, state }) => {
  return (
    <Link
      to={{ pathname, ...(type.value && { search: `?status=${type.value}` }) }}
      className={classNames('btn btn-filter', { active: state === type.value })}
    >
      {type.label}
    </Link>
  );
};

export default StatusFilter;
