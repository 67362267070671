import { first } from 'lodash';
import { useExpanded, useFilters, useGroupBy, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import PageCountSelector from './controls/PageCountSelector';
import Pager from './controls/Pager';
import React from 'react';
import TableComponent from './controls/TableComponent';
import useCheckbox from './controls/useCheckbox';

export default ({ defaultTableProps, defaultOptions }) => {
  const { filters: controlledFilters = {}, fetchData, pageCount } = defaultOptions;
  const tableProps = useTable(
    {
      pageCount,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      disableSortRemove: true,
      useControlledState: (state) => {
        return React.useMemo(() => {
          return {
            ...state,
            pageIndex: controlledFilters.pageIndex || 0,
            pageSize: controlledFilters.pageSize || 10,
          };
        }, [state]);
      },
      ...defaultTableProps,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useCheckbox(defaultOptions)
  );

  const {
    state: { pageIndex, pageSize, sortBy },
  } = tableProps;

  const [_filters, _setFilters] = React.useState();
  const [_pageIndex, _setPageIndex] = React.useState(pageIndex);
  const [_pageSize, _setPageSize] = React.useState(pageSize);

  React.useEffect(() => {
    let sorting;
    if (sortBy.length) {
      const { id, desc } = first(sortBy);
      sorting = {
        sort_by: id,
        order_by: desc ? 'desc' : 'asc',
      };
    }

    _setFilters({ pageIndex: _pageIndex, pageSize: _pageSize, ...(sorting && { sorting }) });
  }, [_pageIndex, _pageSize, sortBy]);

  React.useEffect(() => {
    if (_filters) {
      fetchData && fetchData(_filters);
    }
  }, [_filters, fetchData]);

  return (
    <>
      <TableComponent {...tableProps} defaultOptions={defaultOptions} rows={tableProps.rows} />
      <div className="table-footer d-flex align-items-center">
        <PageCountSelector
          onSetPageSize={(value) => {
            _setPageSize(value);
            _setPageIndex(0);
          }}
          options={defaultOptions}
          pageSize={pageSize}
        />
        <Pager onChange={(page) => _setPageIndex(page)} {...tableProps} />
      </div>

      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              sortBy,
              groupBy,
              expanded: expanded,
              filters,
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map((d) => d.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
    </>
  );
};
