import './scss/main.scss';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import App from './App';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import stores from 'stores';
import styled from 'styled-components';

configure({
  enforceActions: 'never',
});

let ErrorBoundary = styled.div``;
if (process.env.NODE_ENV !== 'development') {
  const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_API);
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
}

ReactDOM.render(
  <Provider {...stores}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// TODO: Add export for inventory
