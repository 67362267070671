import { action, makeObservable, observable } from 'mobx';

class AuthStore {
  constructor(props) {
    makeObservable(this, {
      user: observable,
      profile: action,
    });

    this.api = props.api;
    this.apiFeatures = props.apiFeatures;
    this.accessibleWarehouse = props.accessibleWarehouse;
  }

  user = {};

  getToken = () => {
    return this.api.getToken();
  };
  setToken = (token) => {
    return this.api.setToken(token);
  };
  getApiFeatures = () => {
    return this.apiFeatures;
  };
  getAccessibleWarehouse = () => {
    return this.accessibleWarehouse;
  };
  setAccessibleWarehouse = (apiFeatures) => {
    const result = [];
    for (const item of apiFeatures) {
      switch (item) {
        case 'WAREHOUSE_OPERATION_MDG':
          result.push('MDG');
          break;
        case 'WAREHOUSE_OPERATION_WEST_NETWORK':
          result.push('West Network');
          break;
        case 'WAREHOUSE_OPERATION_FRONTIER_US':
          result.push('Frontier US');
          break;
        case 'WAREHOUSE_OPERATION_PEPLINK_LT':
          result.push('Peplink LT');
          break;
        case 'ORDER_SHIPMENT_SHOW_UNIT_PRICE':
          break;
        case 'MARKETPLACE_API_ACCESS':
          break;
      }
    }
    this.accessibleWarehouse = result;
  };
  logout = () => {
    return this.api.removeToken();
  };
  login = (form) => {
    return new Promise((resolve, reject) => {
      this.api
        .login(form)
        .then((res) => {
          this.setToken(res.data.access_token);
          resolve(res.data.access_token);
          this.setAccessibleWarehouse(res.data.api_features);
        })
        .catch((err) => reject(err.response));
    });
  };

  profile = () => {
    return new Promise((resolve) => {
      this.api.profile().then((res) => {
        this.user = res;
        resolve(res);
      });
    });
  };
}

export default AuthStore;
