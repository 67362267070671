import { SHIPMENT_STATUS } from 'definitions';

export const getSerialItems = (items, key = 'sns') => {
  return (
    items.reduce((acc, value) => {
      const sns =
        (value[key] &&
          value[key].reduce((a, sn) => {
            return [...a, { sku: value.sku, sn }];
          }, [])) ||
        [];
      return [...acc, ...sns];
    }, []) || []
  );
};

export const mergeSerials = (items) => {
  return Object.values(
    items.reduce((acc, value) => {
      return acc[value.sku]
        ? {
            ...acc,
            [value.sku]: {
              sku: value.sku,
              quantity: acc[value.sku].quantity + 1,
              sns: [...acc[value.sku].sns, value.sn],
            },
          }
        : { ...acc, [value.sku]: { sku: value.sku, quantity: 1, sns: [value.sn] } };
    }, [])
  );
};

export const filterData = (filter, data) =>
  data.filter((obj) => {
    return Object.keys(obj).reduce((acc, value) => {
      if (typeof obj[value] === 'string') {
        return acc || obj[value].toLowerCase().includes(filter.toLowerCase());
      } else {
        return acc;
      }
    }, false);
  });

export const getDisabled = (order) =>
  order.lines
    .filter((i) => !i.requiring_sn)
    .map((line) => {
      const draft = order.shipment_draft && order.shipment_draft.items.find((item) => item.sku === line.sku);
      return {
        ...line,
        quantity: 0,
        disabled: true,
        ...(draft && { quantity: draft.quantity, sns: draft.sns }),
      };
    });

export const getReceivingProducts = (items) => {
  let disabled = [];
  const products =
    items.reduce((acc, value) => {
      if (!value.sns.length && value.quantity - value.receivedQuantity > 0) {
        disabled = [
          ...disabled,
          { sku: value.sku, quantity: 0, remaining: value.quantity - value.receivedQuantity, disabled: true },
        ];
      }
      const filtered = value.sns
        .filter((i) => !value.receivedSns.find((received) => i === received))
        .map((i) => ({ sku: value.sku, sn: i }));
      return [...acc, ...filtered];
    }, []) || [];
  return {
    disabled,
    products,
  };
};

export const getStatus = (status, lines = []) => {
  const isPartial = lines.some((i) => i.receivedQuantity > 0);
  switch (status) {
    case SHIPMENT_STATUS.RECEIVED.value:
      return SHIPMENT_STATUS.COMPLETED;
    case SHIPMENT_STATUS.DELIVERED.value:
      if (isPartial) {
        return SHIPMENT_STATUS.PARTIAL_RECEIVED;
      } else {
        return SHIPMENT_STATUS.PENDING_RECEIVE;
      }
    default:
      return SHIPMENT_STATUS[status] || {};
  }
};
