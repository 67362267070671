import React from 'react';

export const InputWithClearButton = ({ onChange, value }) => {
  return (
    <>
      <input
        type="text"
        className="form-control h-auto"
        aria-label="Text input with dropdown button"
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
      {!!value.length && (
        <button className="btn-clear" onClick={() => onChange('')}>
          <i className="material-icons md-dark md-22">close</i>
        </button>
      )}
    </>
  );
};

export default InputWithClearButton;
