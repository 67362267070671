/* eslint-disable no-unused-vars */
import Checkbox from 'components/common/Checkbox';
import cx from 'classnames';
import React from 'react';

export default (defaultOptions) => {
  const { isMulti, disabledShowOnly, disabledProp } = defaultOptions;
  return (hooks) => {
    if (isMulti) {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'check_box',
            groupByBoundary: true,
            Header: (props) => {
              const { getToggleAllRowsSelectedProps, toggleRowSelected, page } = props;
              const items = page.filter((i) => !i.original[disabledProp]);
              const checked = items.length ? items.every((i) => i.isSelected) : false;
              const { indeterminate, ...rest } = getToggleAllRowsSelectedProps();
              const handleChange = () => {
                page.forEach((row) => {
                  const isRowDisabled = !!row.original[disabledProp];
                  toggleRowSelected(row.id, !isRowDisabled && !checked);
                });
              };
              return <Checkbox {...rest} onChange={handleChange} checked={!disabledShowOnly && checked} />;
            },
            Cell: ({ row }) => {
              const { indeterminate, ...rest } = row.getToggleRowSelectedProps();
              const isRowDisabled = !!row.original[disabledProp];
              return !disabledShowOnly ? (
                <Checkbox {...rest} disabled={isRowDisabled} className={cx({ 'force-disabled': isRowDisabled })} />
              ) : (
                !isRowDisabled && <Checkbox {...rest} />
              );
            },
          },
          ...columns,
        ];
      });
    }
  };
};
