import React from 'react';
import styled from 'styled-components';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.name = props.name;
  }

  handleKeyDown = (e) => {
    const { type } = this.props;
    if (type !== 'currency') {
      switch (e.key) {
        case '-':
        case '.':
        case 'e':
          e.preventDefault();
          break;
        default:
      }
    }
  };
  render() {
    const { name, value = '', ...rest } = this.props;

    return (
      <NumberInputStyled
        name={name}
        type="number"
        defaultValue={value}
        key={value}
        onKeyDown={this.handleKeyDown}
        {...rest}
      />
    );
  }
}

const NumberInputStyled = styled.input`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  text-align: center;
`;

export default NumberInput;
