import { mapProductName } from 'utils/mapper';
import { toast } from 'components/common/ReactToast';
import BaseStore from './base';
import stores from './index';

class OrderStore extends BaseStore {
  constructor(props) {
    super(props);
    this.api = props.api;
  }
  find = (id, showNames = false) => {
    return new Promise((resolve, reject) => {
      this.CRUD.isLoading = true;
      this.api
        .find(id)
        .then((res) => {
          const sns = Object.values(
            res.confirmed_shipments
              .reduce((a, val) => {
                return [...a, ...val.items];
              }, [])
              .reduce((acc, value) => {
                return acc[value.sku]
                  ? {
                      ...acc,
                      [value.sku]: {
                        ...value,
                        sns: [...acc[value.sku].sns, ...value.sns],
                        confirmedQuantity: acc[value.sku].confirmedQuantity + value.quantity,
                      },
                    }
                  : {
                      ...acc,
                      [value.sku]: { sku: value.sku, sns: [...value.sns], confirmedQuantity: value.quantity },
                    };
              }, {})
          );
          res.lines = res.lines.map((i) => {
            const found = sns.find((sn) => i.sku === sn.sku);
            if (found) {
              return { ...found, ...i };
            }
            return i;
          });
          this.CRUD.data = res;

          if (showNames) {
            const skus = res.lines.reduce((acc, value) => [...acc, value.sku], []);
            stores.productStore.search(skus).then((product) => {
              this.CRUD.data.lines = mapProductName(this.CRUD.data.lines, product);
              resolve(res);
            });
          } else {
            resolve(res);
          }
        })
        .catch((err) => reject(err))
        .finally(() => {
          this.CRUD.isLoading = false;
        });
    });
  };

  updateOrderShipmentShipmentDraftRequest = (payload) => {
    return new Promise((resolve, reject) => {
      this.api
        .updateOrderShipmentShipmentDraftRequest(payload)
        .then((res) => {
          toast.notify({
            type: 'info',
            value: 'Saved as draft',
          });
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  cancelOrder = (payload) => this.api.cancelOrder(payload);

  getRemarks = (orderid) => this.api.getRemarks(orderid);
  addRemark = (orderid, payload) => this.api.addRemark(orderid, payload);
  updateRemark = (orderid, id, payload) => this.api.updateRemark(orderid, id, payload);
  deleteRemark = (orderid, id) => this.api.deleteRemark(orderid, id);
}

export default OrderStore;
