import { debounce, isEmpty } from 'lodash';
import { ErrorMessage } from '@hookform/error-message';
import { getReceivingProducts, mergeSerials } from 'utils/helpers';
import { inject, observer } from 'mobx-react';
import { toast } from 'components/common/ReactToast';
import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import Modal from 'components/common/DEPRECATED/Modal';
import moment from 'moment';
import NumberInput from 'components/common/NumberInput';
import React from 'react';
import ReactSelect from 'components/common/ReactSelect';
import ReactTable from 'components/common/ReactTable';
import styled from 'styled-components';
import useDisabledItems from 'utils/useDisabledItems';
import useToggleModal from 'utils/useToggleModal';

const getInitialFormState = () => ({
  isNext: false,
  method: { label: 'Airway Bill No.', value: 'waybill' },
  sns: '',
  snsTxt: '',
  selectedOrder: undefined,
  confirmReceipts: [],
  errors: [],
});

const getInitialPageState = () => ({
  items: [],
  selected: [],
});

const Confirm = (props) => {
  const methods = useForm();
  const { register, errors, setValue, handleSubmit } = methods;
  const { receivingStore } = props;
  const [form, setForm] = React.useState(getInitialFormState());
  const [page, setPage] = React.useState(getInitialPageState());
  const [serials, setSerials] = React.useState();
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedDisabled, filterDisabled, onChangeDisabled } = useDisabledItems();
  const { isOpen, toggleModal } = useToggleModal({
    confirmModal: false,
  });
  const [qtyInput, setQtyInput] = React.useState({});

  React.useEffect(() => {
    if (serials) {
      debounceRef.current(serials);
    }
  }, [serials]);

  const debounceRef = React.useRef(
    debounce((newValue) => {
      setForm((prevState) => ({ ...prevState, snsTxt: newValue }));
    }, 500)
  );

  const handleConfirmReceipt = () => {
    const quantities = page.items
      .filter((i) => i.quantity)
      .map(({ sku, quantity }) => {
        return {
          id: form.order.id,
          receiving_date: moment().format(),
          items: [{ sku, quantity }],
        };
      });
    const items = [
      ...quantities,
      ...Object.values(
        page.selected.reduce((acc, value) => {
          return acc[value.receiving_id]
            ? {
                ...acc,
                [value.receiving_id]: {
                  id: value.receiving_id || form.order.id,
                  items: [...acc[value.receiving_id].items, value],
                  receiving_date: moment().format(),
                },
              }
            : {
                ...acc,
                [value.receiving_id]: {
                  id: value.receiving_id || form.order.id,
                  receiving_date: moment().format(),
                  items: [value],
                },
              };
        }, [])
      ).reduce((acc, value) => {
        return [...acc, { ...value, items: mergeSerials(value.items) }];
      }, []),
    ];

    if (!items.length) return false;
    const errors = [];
    const promises = items.map((item) => receivingStore.update(item).catch((err) => errors.push(err.data.errors)));

    Promise.all(promises).then(() => {
      if (errors.length) {
        setForm((prevState) => ({ ...prevState, errors: errors }));
      } else {
        toast.notify({
          value: 'Products have already moved into your inventory.',
          type: 'info',
        });
        setSerials('');
        setForm(getInitialFormState());
      }
    });
  };

  React.useEffect(() => {
    setData(page.items.reverse());
    filterDisabled(page.items);
  }, [filterDisabled, page.items]);

  React.useEffect(() => {
    const isManual = form.method.value === 'manual';
    const sns = form.snsTxt
      .split('\n')
      .filter((i) => i)
      .map((i) => i.trim());
    if (sns.length) {
      setIsLoading(true);
      receivingStore
        .serialNumbers({
          sns,
        })
        .then((res) => {
          const confirmReceipts = isManual ? [] : form.confirmReceipts;
          const results = res.result.map((i) => ({ ...i, ...(i.error && { disabled: true }) }));
          setIsLoading(false);
          setForm((prevState) => ({ ...prevState, errors: [] }));
          setPage((prevState) => ({ ...prevState, items: [...confirmReceipts, ...results] }));
        });
    }
  }, [receivingStore, form.sns, form.snsTxt, form.method, form.confirmReceipts]);

  const fetchOrders = React.useCallback(() => {
    return new Promise((resolve) => {
      receivingStore
        .search({
          page_size: 999,
          page_num: 0,
          status: 'SHIPMENT_TO_RECEIVE',
        })
        .then((res) => resolve(res.items))
        .catch((err) => {
          if (
            err.data.errors === 'Invalid path name' ||
            err.data.errors === 'You are not allowed to access this resource'
          )
            history.push('/notFound');
        });
    });
  }, [receivingStore]);

  const handleChangeWaybill = (selected) => {
    receivingStore.find(selected.id).then((res) => {
      const { disabled, products } = getReceivingProducts(res.lines);
      setPage((prevState) => ({ ...prevState, selected: [], items: [...disabled, ...products] }));
      setForm((prevState) => ({
        ...prevState,
        order: res,
        selectedOrder: selected,
      }));
    });
  };

  const handleClearPage = () => {
    setSerials('');
    setForm(getInitialFormState());
  };

  const selectedRows = React.useMemo(() => {
    return page.selected.length + selectedDisabled.length;
  }, [page.selected, selectedDisabled]);

  const handleSelectionChange = React.useCallback(
    (selected) => {
      if (!isEqual(selected, page.selected)) {
        setPage((prevState) => ({ ...prevState, selected: [...selected] }));
      }
    },
    [page.selected]
  );

  const matchingResults = React.useMemo(() => {
    return data.reduce(
      (acc, value) => {
        return value.error ? { ...acc, errors: acc.errors + 1 } : { ...acc, valid: acc.valid + 1 };
      },
      { valid: 0, errors: 0 }
    );
  }, [data]);

  const defaultSelected = React.useMemo(() => {
    return data
      .filter((i) => !i.disabled)
      .reduce((acc, value, key) => {
        return {
          ...acc,
          [key]: true,
        };
      }, {});
  }, [data]);

  const quantityRef = React.useRef(
    debounce((value, item) => {
      onChangeDisabled(value, item);
    }, 500)
  );

  const columns = React.useMemo(() => {
    const handleChange = (value, item) => {
      item.quantity = value;
      quantityRef.current(value, item);
    };

    return [
      {
        Header: 'Serial No.',
        accessor: 'sn',
      },
      {
        Header: 'Product Code',
        accessor: 'sku',
      },
      {
        Header: 'Remarks',
        accessor: 'error',
        className: 'match-remarks',
        Cell: ({ cell: { value } }) => (
          <React.Fragment>
            {value && (
              <React.Fragment>
                <i className="material-icons md-18 pl-0 pr-1">error_outline</i> {value}
              </React.Fragment>
            )}
          </React.Fragment>
        ),
      },
      {
        Header: 'Received Quantity',
        className: 'text-center',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          if (!original.sn) {
            const found = page.items.find((i) => i.sku === original.sku);
            const name = `${original.sku}`;
            setQtyInput((prevState) => ({ ...prevState, [name]: name }));

            return (
              <NumberInput
                name={name}
                ref={register({
                  min: {
                    value: 0,
                    message: `${name} is required`,
                  },
                  max: {
                    value: found.remaining,
                    message: `${name} is invalid`,
                  },
                })}
                defaultValue={original.quantity}
                className="m-auto confirm-qty-box form-control"
                onChange={(e) => {
                  const {
                    target: { value, name },
                  } = e;
                  setValue(name, value, { shouldValidate: true });
                  handleChange(value, original);
                }}
              />
            );
          }
          return null;
        },
      },
    ];
  }, [page, register, setValue]);

  const canNext = form.method.value === 'manual' ? serials : form.selectedOrder;

  return (
    <>
      <div className="container-fluid p-md-5 p-3">
        <div className="breadcrumb">
          <h6>Home {'>'} Confirm Shipment</h6>
        </div>
        <div className="head-wrapper">
          <div className="row">
            <div className="head-title col-12">
              <h4>Confirm Shipment</h4>
            </div>
          </div>
        </div>

        <div className="tracking mt-3">
          <div className="row">
            <div className="panel tracking-panel col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="card shadow-sm">
                <div className="pt-3 px-4 pb-4">
                  <div className="title">Tracking Information</div>
                  <div className="form-group pt-2">
                    <label htmlFor="tracking-method" className="mb-0">
                      Method
                    </label>
                    <ReactSelect
                      name="tracking-method"
                      onChange={(selected) =>
                        setForm((prevState) => ({
                          ...prevState,
                          isNext: false,
                          method: selected,
                          selectedOrder: undefined,
                        }))
                      }
                      value={form.method}
                      options={[
                        { label: 'Airway Bill No.', value: 'waybill' },
                        { label: 'Manual Input / Barcode Scanner', value: 'manual' },
                      ]}
                    />
                  </div>

                  {form.method.value === 'waybill' ? (
                    <div className="form-group">
                      <label htmlFor="awb" className="mb-0">
                        AWB No.
                      </label>
                      <ReactSelect
                        name="awb"
                        onChange={handleChangeWaybill}
                        getOptionLabel={(option) => option.waybill}
                        getOptionValue={(option) => option.id}
                        key={form.selectedOrder}
                        value={form.selectedOrder}
                        loadOptions={fetchOrders}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label>
                        Serial No. <small>(one per line)</small>
                      </label>
                      <textarea
                        name="sns"
                        onChange={(e) => setSerials(e.target.value)}
                        value={serials}
                        className="form-control mt-1"
                        id="sn-box"
                        rows="10"
                      ></textarea>
                    </div>
                  )}
                  <div className="confirm-action d-flex justify-content-end">
                    {!form.isNext && (
                      <button
                        type="button"
                        onClick={() => setForm((prevState) => ({ ...prevState, isNext: true }))}
                        className="btn btn-primary btn-next"
                        disabled={!canNext}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {form.isNext && (
              <div className="panel matching-result col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4">
                {!!form.errors.length && (
                  <div className="alert alert-danger">
                    {form.errors.map((i, key) => (
                      <div key={`error-${key}`}>{i.message}</div>
                    ))}
                  </div>
                )}
                {!isEmpty(errors) && (
                  <div className="alert alert-danger">
                    <ListStyled>
                      {Object.keys(qtyInput).map((name, key) => (
                        <ErrorMessage key={key + name} errors={errors} name={name}>
                          {({ messages }) => {
                            return (
                              messages &&
                              Object.entries(messages).map(([type, message]) => <li key={type}>{message}</li>)
                            );
                          }}
                        </ErrorMessage>
                      ))}
                    </ListStyled>
                  </div>
                )}
                <div className="card shadow-sm">
                  <div className="pt-3 px-4 pb-4">
                    <div className="title">{`${
                      data.length
                        ? `${matchingResults.valid} matching result(s)${
                            matchingResults.errors ? `, ${matchingResults.errors} error(s)` : ''
                          }`
                        : 'Matching Result'
                    }`}</div>
                    <ReactTable
                      data={data}
                      columns={columns}
                      options={{
                        selectKeyField: 'sn',
                        disabledProp: 'disabled',
                        isLoading,
                        onSelectionChanged: handleSelectionChange,
                        isMulti: true,
                        ...(form.method.value === 'manual' && { ...{ defaultSelected } }),
                      }}
                    />
                    <div id="selected-qty">{`Selected Items: ${selectedRows}`} </div>
                  </div>
                </div>

                <div className="confirm-action d-flex justify-content-end mt-5">
                  <button className="btn btn-dark btn-discard mr-3" onClick={handleClearPage}>
                    Clear
                  </button>
                  <button onClick={handleSubmit(handleConfirmReceipt)} className="btn btn-primary btn-confirm">
                    Confirm Receipt
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpen.confirmModal && (
        <Modal
          size="sm"
          title="Warning"
          submitText="Confirm"
          onSubmit={handleClearPage}
          onToggle={() => toggleModal('confirmModal')}
        >
          <p className="m-0">The entered data will be discard.</p>
        </Modal>
      )}
    </>
  );
};

export default inject('receivingStore')(observer(Confirm));

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
