import AuthStore from './auth';
import CommonStore from './common';
import InventoryStore from './inventory';
import OrderStore from './order';
import ProductStore from './product';
import ReceivingStore from './receiving';
import SerialNumberStore from './serialNumber';
import services from 'services';
import StockStore from './stock';

export default {
  commonStore: new CommonStore(),
  authStore: new AuthStore({ api: services.authServices }),
  inventoryStore: new InventoryStore({ api: services.inventoryServices }),
  receivingStore: new ReceivingStore({ api: services.receivingServices }),
  orderStore: new OrderStore({ api: services.orderServices }),
  productStore: new ProductStore({ api: services.productServices }),
  serialNumberStore: new SerialNumberStore({ api: services.serialNumberServices }),
  stockStore: new StockStore({ api: services.stockServices }),
};
