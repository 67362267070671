import { ROUTE } from 'definitions';
import axios from 'axios';
const APP_NAME = process.env.REACT_APP_APP_NAME;

const headers = (params) => {
  return {
    headers: {
      ...params,
    },
  };
};

const responseParser = (res) => res.data;
const errorParser = (res) => {
  console.error(res);
  return res.response;
};

export default class Http {
  constructor() {
    this.axiosInstance = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_API_SERVER,
    });

    this.axiosInstance.interceptors.response.use(
      function(config) {
        return config;
      },
      (error) => {
        if (error.message === 'Network Error') {
          // window.location = '/504';
        }

        const {
          response: {
            status = null,
            data: { message },
          },
        } = error;
        switch (status) {
          case 500:
            throw new Error(JSON.stringify(message));
          // return Promise.reject({ response: { data: { errors: { message } } } });
          case 404:
            window.location = `/404`;
            break;
          case 401:
            sessionStorage.setItem('REDIRECT', window.location.pathname);
            localStorage.removeItem(`${APP_NAME}_TOKEN`);
            window.location = `/${ROUTE.LOGIN}`;
            return Promise.reject(error);
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  }

  getToken = () => localStorage.getItem(`${APP_NAME}_TOKEN`);

  setToken = (token) => {
    localStorage.setItem(`${APP_NAME}_TOKEN`, token);
  };
  removeToken = () => {
    localStorage.removeItem(`${APP_NAME}_TOKEN`);
  };

  get = (url, payload = {}) => {
    const config = Object.assign({ params: payload }, headers({ Token: this.getToken() }));
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  post = (url, payload) => {
    const config = Object.assign(headers({ Token: this.getToken() }), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  put = (url, payload) => {
    const config = Object.assign(headers({ Token: this.getToken() }), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  delete = (url) => {
    const config = Object.assign(headers({ Token: this.getToken() }), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(url, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
}
