import { Button, ModalBody, ModalFooter, ModalHeader, Modal as ReactModal } from 'reactstrap';
import cx from 'classnames';
import React, { useState } from 'react';
export const Modal = (props) => {
  const {
    id,
    size,
    title,
    cancelText = 'Cancel',
    submitText = 'Update',
    onSubmit,
    onToggle,
    className,
    bodyClassName,
    children,
    actions,
    disabled,
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      onToggle();
    }, 300);
  };
  return (
    <ReactModal size={size} isOpen={isOpen} toggle={handleToggle} className={className} id={id}>
      <ModalHeader toggle={handleToggle}>{title}</ModalHeader>
      <ModalBody className={cx('py-0', bodyClassName)}>{children}</ModalBody>
      <ModalFooter>
        {actions && actions() ? (
          actions()
        ) : (
          <React.Fragment>
            <Button color="dark" onClick={handleToggle}>
              {cancelText}
            </Button>
            {onSubmit && (
              <Button type="submit" color="primary" onClick={onSubmit} disabled={disabled}>
                {submitText}
              </Button>
            )}
          </React.Fragment>
        )}
      </ModalFooter>
    </ReactModal>
  );
};

export default Modal;
