import React from 'react';
import ReactDOM from 'react-dom';

class ConfirmManager {
  constructor() {
    const el = document.createElement('div');
    el.id = 'portal';
    if (document.body != null) {
      document.body.appendChild(el);
    }
    this.portalElement = el;
  }
  show = ({ props, Component }) => {
    ReactDOM.render(<Component onToggle={this.close} onClose={this.close} {...props} />, this.portalElement);
  };
  close = () => {
    ReactDOM.render(null, this.portalElement);
  };
}

export default new ConfirmManager();
