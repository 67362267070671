import { formatDateTime } from 'utils/formatters';
import { inject, observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import confirm from 'components/common/ConfirmManager';
import ConfirmDialog from 'components/common/ConfirmDialog';
import React from 'react';
import styled from 'styled-components';

const CommentItem = ({ onUpdate, orderId, data, authStore, orderStore }) => {
  const [isEdit, setIsEdit] = React.useState();
  const methods = useForm();
  const { register, reset, handleSubmit } = methods;
  const user = React.useMemo(() => authStore.user, [authStore.user]);

  const onSubmit = async (form) => {
    await orderStore.updateRemark(orderId, data.id, form);
    onUpdate();
    setIsEdit(false);
  };

  React.useEffect(() => {
    reset(data);
  }, [data, reset]);

  const ShowConfirm = () => {
    confirm.show({
      props: {
        message: 'Are you sure you want to delete',
        onConfirm: () => {
          return new Promise((resolve, reject) => {
            orderStore
              .deleteRemark(orderId, data.id)
              .then(() => {
                onUpdate();
                resolve();
              })
              .catch((err) => reject(err.data.errors.message));
          });
        },
      },
      Component: ConfirmDialog,
    });
  };

  return (
    <CommentItemStyled key={data.id} className="mb-3">
      <div className="d-flex mb-1 align-items-end">
        <strong>{data.created_by}</strong>
        <small className="ml-auto">{formatDateTime(data.modified_date)}</small>
      </div>
      <div className="py-2 px-3 mb-1 bg-secondary rounded-sm">
        {isEdit ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <textarea
              ref={register({ required: true })}
              name="remark"
              rows="4"
              className="form-control rounded-sm"
            ></textarea>
            <div className="mt-2 d-flex">
              <button className="ml-auto" onClick={() => setIsEdit(false)}>
                Cancel
              </button>
              <button onClick={() => setIsEdit(true)}>Save</button>
            </div>
          </form>
        ) : (
          <>
            <p className="mb-0">{data.remark}</p>
            {data.created_by === user.user_name && (
              <div className="mt-2">
                <button onClick={() => setIsEdit(true)}>Edit</button>
                <button onClick={ShowConfirm}>Delete</button>
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex">
        <small className="ml-auto">Created on {formatDateTime(data.created_date)}</small>
      </div>
    </CommentItemStyled>
  );
};

export default inject(({ authStore, orderStore }) => ({ authStore, orderStore }))(observer(CommentItem));

const CommentItemStyled = styled.div`
  button {
    background: none;
    border: none;
    font-weight: bold;
    padding: 0;
  }
  button + button {
    margin-left: 0.5rem;
  }
  p {
    white-space: pre-wrap;
  }
`;
