import { inject, observer } from 'mobx-react';
import { Toast, ToastBody } from 'reactstrap';
import React, { useEffect, useRef } from 'react';

import stores from 'stores';

class Toastify {
  notify = (item) => {
    stores.commonStore.toasters = [...stores.commonStore.toasters, item];
  };
  remove = (item) => {
    stores.commonStore.toasters = [...stores.commonStore.toasters.filter((i) => i !== item)];
  };
}

const Template = ({ item, onClose }) => {
  const { autoHide = true, delay = 5000, ...rest } = item;
  let timer = useRef(null);
  useEffect(() => {
    if (autoHide) {
      timer.current = setTimeout(onClose, delay);
    }
    return () => {
      clearTimeout(timer.current);
    };
  });
  return (
    <Toast>
      <ToastBody className="d-flex align-items-center">
        <i className="material-icons md-dark md-24 pl-0 pr-2">error_outline</i>
        <div className="toast-message">{rest.value}</div>
        <button onClick={onClose} type="button" className="close pr-0 ml-auto" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </ToastBody>
    </Toast>
  );
};

const ReactToast = ({ commonStore }) => {
  return (
    <div id="snackbar" aria-live="polite" aria-atomic="true">
      {commonStore.toasters.map((item, key) => (
        <Template key={key} item={item} onClose={() => toast.remove(item)} />
      ))}
    </div>
  );
};

export const toast = new Toastify();

export default inject('commonStore')(observer(ReactToast));
