import { makeObservable, observable } from 'mobx';
import BaseStore from './base';
class ProductStore extends BaseStore {
  constructor(props) {
    super(props);
    makeObservable(this, {
      skuMap: observable,
      skus: observable,
    });
    this.api = props.api;
  }

  skuMap = [];
  skus = [];

  search = (skus) => {
    return new Promise((resolve, reject) => {
      const filteredItems = skus.filter((item) => this.skuMap.indexOf(item) < 0);
      this.skuMap = this.skuMap.concat(filteredItems);
      if (filteredItems.length) {
        this.api
          .search({
            page_num: 0,
            page_size: filteredItems.length,
            skus: filteredItems,
          })
          .then((res) => {
            this.skus = [...this.skus, ...res.items];
            resolve(this.skus);
          })
          .catch((err) => reject(err));
      } else {
        resolve(this.skus);
      }
    });
  };
}

export default ProductStore;
