import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import PageCountSelector from './controls/PageCountSelector';
import Pager from './controls/Pager';
import React from 'react';
import TableComponent from './controls/TableComponent';
import useCheckbox from './controls/useCheckbox';

export default React.forwardRef(({ defaultTableProps, defaultOptions }, ref) => {
  const tableProps = useTable(
    {
      ...defaultTableProps,
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useCheckbox(defaultOptions)
  );

  const {
    gotoPage,
    setPageSize,
    state: { pageSize },
  } = tableProps;

  React.useEffect(() => {
    if (ref) {
      ref.current = tableProps;
    }
  }, [tableProps, ref]);

  return (
    <>
      <TableComponent {...tableProps} defaultOptions={defaultOptions} rows={tableProps.page} />
      <div className="table-footer d-flex align-items-center">
        <PageCountSelector
          onSetPageSize={(value) => {
            gotoPage(0);
            setPageSize(value);
          }}
          options={defaultOptions}
          pageSize={pageSize}
        />
        <Pager onChange={(page) => gotoPage(page)} {...tableProps} />
      </div>
    </>
  );
});
