import { Link } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Panel = ({ item, filter }) => {
  const currentWarehouse = window.location.pathname.split('/')[2];
  return (
    <PanelStyled id="the-records" className="col-sm-6 col-md-6 col-lg-6 col-xl-3 p-3">
      <Link
        to={{
          pathname: `${currentWarehouse}`,
          ...(item.value && { search: `?filter=${item.value}` }),
        }}
        className="card shadow-sm cursor-pointer"
      >
        <div className={classNames('title mb-3', { active: filter === item.value })}>{item.title}</div>
        <div className="d-flex justify-content-between mt-auto">
          <div className="filter-img">
            <img src={item.image} height="46" alt="product" />
          </div>
          <div className="text-right">
            <div className="count-number">{item.count}</div>
            <button className="btn-clear view-items text-link">View Items</button>
          </div>
        </div>
      </Link>
    </PanelStyled>
  );
};

export default Panel;

const PanelStyled = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
`;
