import React from 'react';

const useToggleModal = (modals) => {
  const [isOpen, setIsOpen] = React.useState(modals);
  const toggleModal = React.useCallback(
    (modal) => {
      setIsOpen((prevState) => ({
        ...prevState,
        [modal]: !isOpen[modal],
      }));
    },
    [isOpen]
  );

  return {
    isOpen,
    setIsOpen,
    toggleModal,
  };
};

export default useToggleModal;
