import axios from 'axios';
const baseURL = process.env.REACT_APP_API_SERVER;

export default class authServices {
  constructor(props) {
    this.http = props.http;
  }

  getToken = () => {
    return this.http.getToken();
  };

  setToken = (token) => {
    return this.http.setToken(token);
  };
  removeToken = () => {
    return this.http.removeToken();
  };

  login = (form) => {
    return axios.post(`${baseURL}/authentication`, form);
  };

  profile = () => {
    return this.http.get('/user_profile');
  };
}
