import BaseServices from './base';

export default class orderServices extends BaseServices {
  constructor(props) {
    props.url = '/order_shipment';
    super(props);
    this.http = props.http;
  }

  updateOrderShipmentShipmentDraftRequest = (payload) => {
    const { id, ...rest } = payload;
    return this.http.put(`${this.url}/${id}/shipment_draft`, rest);
  };

  cancelOrder = (payload) => {
    return this.http.put(`${this.url}/cancel`, payload);
  };

  getRemarks = (orderid) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.get(`${this.url}/${currentWarehouse}/${orderid}/remarks`);
  };
  addRemark = (orderid, payload) => this.http.post(`${this.url}/${orderid}/remarks`, payload);
  updateRemark = (orderid, id, payload) => this.http.put(`${this.url}/${orderid}/remarks/${id}`, payload);
  deleteRemark = (orderid, id) => this.http.delete(`${this.url}/${orderid}/remarks/${id}`);
}
