import { debounce } from 'lodash';
import NumberInput from 'components/common/NumberInput';
import React from 'react';
import styled from 'styled-components';

const Pager = function({ state, onChange, canPreviousPage, canNextPage, pageCount }) {
  const [page, setPage] = React.useState(0);
  const debounceRef = React.useRef(
    debounce((newValue) => {
      onChange(newValue);
    }, 500)
  );

  React.useEffect(() => {
    setPage(state.pageIndex || 0);
  }, [state.pageIndex]);

  React.useEffect(() => {
    debounceRef.current(page);
  }, [page]);
  return (
    <div className="ml-auto d-flex align-items-center">
      <PagerStyled className="pagination d-flex align-items-center m-0">
        <li className="page-item">
          <button
            className="page-link material-icons md-dark md-24"
            onClick={() => setPage(page >= 1 ? page - 1 : 0)}
            disabled={!canPreviousPage}
          >
            keyboard_arrow_left
          </button>
        </li>
        <li>
          <form>
            <NumberInput
              value={page + 1}
              onChange={({ target: { value } }) => {
                if (value) {
                  const page = value >= 1 ? Number(value) - 1 : 0;
                  setPage(page < pageCount ? page : pageCount - 1);
                }
              }}
            />{' '}
            of {pageCount}
          </form>
        </li>

        <li className="page-item">
          <button
            className="page-link material-icons md-dark md-24"
            onClick={() => setPage(page + 1)}
            disabled={!canNextPage || page + 1 >= pageCount}
          >
            keyboard_arrow_right
          </button>
        </li>
      </PagerStyled>
    </div>
  );
};

export default Pager;

const PagerStyled = styled.ul`
  button:disabled {
    opacity: 0.2;
  }
`;
