import cx from 'classnames';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

export default ({
  methods = {},
  id = shortid.generate(),
  rules = {},
  children,
  type = 'checkbox',
  name,
  className,
  ...rest
}) => {
  const { register } = methods;

  return (
    <CheckboxStyled className={cx('custom-control custom-checkbox d-flex', className)}>
      <input
        type={type}
        name={name}
        className="custom-control-input"
        id={id}
        {...(register && { ref: register(rules) })}
        {...rest}
      ></input>

      <label className="d-flex align-items-center custom-control-label" htmlFor={id}>
        {children}
      </label>
    </CheckboxStyled>
  );
};

const CheckboxStyled = styled.div`
  padding: 0;
  margin-bottom: 0;
  input {
    margin-right: 0.5rem;
  }
  .custom-control-label {
    padding-left: 1.5rem;
    &:before,
    &:after {
      left: ${(props) => !props.label && 0};
    }
  }
`;
