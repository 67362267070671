import { debounce } from 'lodash';
import React from 'react';

export const useDebounce = ({ onChange, value: _value }) => {
  const debounceRef = React.useRef(debounce((newValue) => onChange(newValue), 500));
  const [value, _setValue] = React.useState('');
  const [isTyping, setIsTyping] = React.useState(false);
  React.useEffect(() => {
    if (isTyping) {
      debounceRef.current(value);
      setIsTyping(false);
    }
  }, [value, isTyping]);

  React.useEffect(() => {
    if (!_value) {
      _setValue('');
    } else {
      _setValue(_value);
    }
  }, [_value]);

  const setValue = (value) => {
    setIsTyping(true);
    _setValue(value);
  };

  return { value, setValue };
};

export default useDebounce;
