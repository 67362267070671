import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CommentItem from './CommentItem';
import cx from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Comments = ({ orderStore }) => {
  const { id } = useParams();

  const [isOpen, setIsOpen] = React.useState();
  const methods = useForm();
  const [remarks, setRemarks] = React.useState();
  const { handleSubmit, register, reset } = methods;

  const onSubmit = async (data) => {
    await orderStore.addRemark(id, data);
    getData();
    reset();
  };

  const getData = React.useCallback(() => {
    orderStore.getRemarks(id).then((res) => setRemarks(res));
  }, [id, orderStore]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <CommentStyled className={cx('shadow', { 'is-open': isOpen })}>
      <button onClick={() => setIsOpen(!isOpen)} className="shadow btn-toggle material-icons">
        comment
      </button>
      <div className="comments-main p-4">
        <div className="d-flex align-items-center mb-2">
          <strong>COMMENTS</strong>
          <Button close className="ml-auto" onClick={() => setIsOpen(false)} />
        </div>
        <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
          <textarea
            ref={register({ required: true })}
            className="form-control mb-2 rounded-sm"
            name="remark"
            rows="5"
          ></textarea>
          <div className="d-flex">
            <Button size="sm" color="primary" type="submit" className="ml-auto">
              Submit
            </Button>
          </div>
        </form>
        <div className="comments">
          {remarks && remarks.items.map((i) => <CommentItem onUpdate={getData} orderId={id} key={i.id} data={i} />)}
        </div>
      </div>
    </CommentStyled>
  );
};

export default inject(({ orderStore }) => ({ orderStore }))(observer(Comments));

const CommentStyled = styled.div`
  position: fixed;
  transform: translateX(100%);
  transition: all 0.3s ease-in;
  right: 0;
  top: 50px;
  width: 30vw;
  &.is-open {
    transform: translateX(0%);
  }
  .comments-main {
    height: 100vh;
    background-color: #fff;
  }
  .comments {
    font-size: 12px;
    p {
    }
  }

  .btn-toggle {
    z-index: -1;
    position: absolute;
    top: 6%;
    right: 100%;
    background-color: #fff;
    padding: 0.5rem;
  }
`;
