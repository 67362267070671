import BaseServices from './base';
export default class inventoryServices extends BaseServices {
  constructor(props) {
    props.url = '/inventory';
    super(props);
  }
  inventory = () => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.get(`${this.url}/${currentWarehouse}`);
  };

  sns = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/search/${currentWarehouse}/sns`, payload);
  };

  summary = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.get(`${this.url}/summary/${currentWarehouse}`, payload);
  };
}
